<template>
  <div class="aboutme">
    <div class="wrap w clearfix">
      <div class="wrap-left">
        <div class="wrap-left-top">
          <p>{{$t('exhibitors.form.forExhibitor')}}</p>
        </div>
        <div class="wrap-left-bottom">
          <div class="wrap-left-bottom-wrap">
            <el-form ref="form"
                     :model="formData"
                     :rules="rules"
                     size="small"
                     label-position="left"
                     label-width="70px">
              <!-- 展馆 -->
              <el-form-item :label="$t('exhibitors.form.pavilion')"
                            prop="exhibition_tube_id">
                <el-select clearable
                           class="medium-width"
                           v-model="formData.exhibition_tube_id"
                           :placeholder="$t('exhibitors.form.select')">
                  <el-option v-for="item in dataOptions"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <!-- 展区 -->
              <el-form-item :label="$t('exhibitors.form.p_area')"
                            prop="exhibition_area_id">
                <el-select class="medium-width"
                           v-model="formData.exhibition_area_id"
                           :placeholder="$t('exhibitors.form.selectArea')">
                  <el-option v-for="item in ExhibitionData"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <!-- 展位号 -->
              <el-form-item :label="$t('exhibitors.form.boothNo')"
                            prop="booth">
                <el-input class="medium-width"
                          v-model="formData.exhibition_num"
                          :placeholder="$t('exhibitors.form.select_bn')"></el-input>
              </el-form-item>

              <!-- 公司名称 -->
              <el-form-item :label="$t('exhibitors.form.com_name')"
                            prop="company">
                <el-input class="medium-width"
                          v-model="formData.company"
                          :placeholder="$t('exhibitors.form.select_cn')"></el-input>
              </el-form-item>
              <!-- 展团名称 -->
              <el-form-item :label="$t('exhibitors.form.groupName')"
                            prop="pavilion_name">
                <el-input class="medium-width"
                          v-model="formData.pavilion_name"
                          :placeholder="$t('exhibitors.form.select_gn')"></el-input>
              </el-form-item>
              <!--地区  -->
              <el-form-item :label="$t('exhibitors.form.district')"
                            prop="region">
                <el-select v-model="formData.province"
                           class="small-width"
                           style="padding-right:7px"
                           :placeholder="$t('exhibitors.form.province')"
                           @change="chooseProvince">
                  <el-option v-for="item in provinceData"
                             :key="item.code"
                             :label="item.name"
                             :value="item.name"></el-option>
                </el-select>
                <el-select v-model="formData.city"
                           class="small-width ml20"
                           :placeholder="$t('exhibitors.form.city')">
                  <el-option v-for="item in cityData"
                             :key="item.code"
                             :label="item.name"
                             :value="item.name"></el-option>
                </el-select>
              </el-form-item>
              <!-- 展品 -->
              <el-form-item :label="$t('exhibitors.form.exhibits')"
                            prop="exhibitor_cate_id">
                <el-select class="medium-width"
                           clearable
                           v-model="formData.exhibitor_cate_id__arr"
                           :placeholder="$t('exhibitors.form.select_ex')">
                  <el-option v-for="item in optionsExhibits"
                             :key="item.id"
                             :label="item.title"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <div class="btn-wrap">
                <el-button type="primary"
                           style="small"
                           class="form-submit subGet"
                           @click="onSubmit"
                           :loading="loading">{{$t('exhibitors.form.submit')}}</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <div class="wrap-right">
        <el-table stripe
                  v-loading="tableLoading"
                  :data="tableData"
                  class="dm-table"
                  header-row-class-name="dm-table-head"
                  style="width: 100%">
          <el-table-column prop="name"
                           :label="$t('exhibitors.form.pavilion')"
                           width="60"></el-table-column>
          <el-table-column prop="area_name"
                           :label="$t('exhibitors.form.p_area')"
                           width="90"></el-table-column>
          <el-table-column prop="exhibition_num"
                           :label="$t('exhibitors.form.boothNo')"
                           width="110"></el-table-column>
          <el-table-column prop="company"
                           :label="$t('exhibitors.form.com_name')"
                           width="200"></el-table-column>
          <el-table-column prop="city"
                           :label="$t('exhibitors.form.P_C')"
                           width="120"></el-table-column>
          <el-table-column prop="title"
                           :label="$t('exhibitors.form.business_c')"></el-table-column>
          <el-table-column :label="$t('exhibitors.form.operate')"
                           fixed="right"
                           width="80">
            <template slot-scope="scope">
              <el-button @click="handleClickRow(scope.row)"
                         type="text"
                         size="small">{{$t('exhibitors.form.lookDet')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination v-if="total!==0"
                       background
                       class="pageAction"
                       layout="prev, pager, next"
                       @current-change="handleCurrentChange"
                       :page-size="pageSize"
                       :current-page="indexPage"
                       :total="total"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { exhibitorList, exhibitorCate } from "@/api/index";
import { city } from "@/components/city.js";

export default {
  name: "Exhibitors",
  components: {},
  data () {
    return {
      loading: false,
      tableData: [],
      dataOptions: [],
      ExhibitionData: [],
      exhibitsDataOption: [],
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      elForm: {},

      formData: {
        exhibitor_cate_id: "",
        exhibitor_cate_id__arr: "", // 做存储选择数组用
        city: "",
        province: "",
        pavilion_name: "",
        overName: "",
        exhibition_num: "",
        exhibition_area_id: "",
        exhibition_tube_id: ""
      },
      rules: {
        email: [
          { required: true, message: "请输入邮箱", trigger: "change" },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" }
        ]
      },
      provinceData: [],
      cityData: [],
      optionsExhibits: [],
      tableLoading: false,
      total: 0,
      indexPage: 1,
      pageSize: 10,
      defaultPage: {
        // 默认分页信息
        page: 1,
        limit: 10
      }
    };
  },

  watch: {
    $route: {
      handler () {
        let query = this.$route.query;
        if (this.$route.name == 'Exhibitors') {
          if (query.type == "1") {
            this.$router.push({
              name: "instructions"
            });
          } else if (query.type == "4") {
            this.$router.push({
              name: "Service",
              query: {
                type: 4
              }
            });
          } else if (query.type == "2") {
            // 自动转跳后的返回
            if (sessionStorage.getItem('BrandPresence_back')) {
              sessionStorage.removeItem('BrandPresence_back')
              // this.$store.commit('setBannerId', 1)
            }
            this.$router.push({
              name: "BrandPresence",
              query: {
                type: query.role
              }
            });
          }
        }
        // console.log("router", this.$route);
        console.log(query.type, 55558888888)

      },
      deep: true
    }
  },
  mounted () { },
  methods: {
    handleClickRow (row) {
      this.$router.push({
        name: "Streamf",
        query: {
          id: row.id
        }
      });
    },
    chooseProvince (value) {
      this.formData.city = "";
      this.provinceDataCity.map(e => {
        //遍历数据
        if (value == e.name) {
          this.cityData = e.cityList;
          return;
        }
      });
    },
    onSubmit () {
      this.loading = true;
      this.$refs["form"].validate(valid => {
        if (valid) {
          let def = this.$myJson(this.defaultPage); // 默认信息
          let datas = {
            page: this.indexPage,
            limit: this.pageSize,
            ...this.$myJson(this.formData)
          };
          datas.exhibitor_cate_id = datas.exhibitor_cate_id__arr[0] || "";
          delete datas.exhibitor_cate_id__arr;
          // 先修改提交的分页信息
          datas.page = def.page;
          datas.limit = def.limit;
          exhibitorList(datas).then(res => {
            // 再修改分页的分页信息
            this.indexPage = def.page;
            this.pageSize = def.limit;
            this.loading = false;
            this.$message({
              message: "提交成功！",
              type: "success"
            });
            this.tableData = res.data.data;
            this.total = res.data.total;
          });
        } else {
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCurrentChange (val) {
      this.indexPage = val;
      this.tableLoading = true;
      this.getTableDatas(null);
    },
    getTableDatas (data = {}) {
      console.log(this.formData, 555555)
      //展商列表
      let datas = {};
      datas = {
        page: this.indexPage,
        limit: this.pageSize,
        ...this.$myJson(this.formData),
        ...data
      };
      datas.exhibitor_cate_id = datas.exhibitor_cate_id__arr[0] || "";
      delete datas.exhibitor_cate_id__arr;
      exhibitorList(datas).then(res => {
        if (this.tableLoading) {
          setTimeout(() => {
            this.tableLoading = false;
          }, 200);
        }
        if (res.code == "200") {
          let data = res.data;
          this.tableData = data.data;
          this.total = res.data.total;
        }
      });
    }
  },
  created () {
    this.provinceDataCity = city;
    this.provinceData = city.filter(item => {
      return item.name.indexOf("省") > -1;
    });
    let query = this.$route.query;
    // console.log("router", this.$route);
    console.log(query.type, 55558888888)
    if (query.type == "1") {
      this.$router.push({
        name: "instructions"
      });
    } else if (query.type == "4") {
      this.$router.push({
        name: "reserveForm"
      });
    } else if (query.type == "2") {
      // 自动转跳后的返回
      if (sessionStorage.getItem('BrandPresence_back')) {
        sessionStorage.removeItem('BrandPresence_back')
        // this.$store.commit('setBannerId', 1)
      }
      this.$router.push({
        name: "BrandPresence",
        query: {
          type: query.role
        }
      });
    }
    //展商列表
    this.getTableDatas();

    //展商分类（展馆、展区、展品）
    exhibitorCate({
      // 'page': page,
      // 'limit': limit
    }).then(res => {
      if (res.code == "200") {
        const datas = res.data;
        datas.exhibition_tube.map(item => {
          this.dataOptions.push({
            label: item.name,
            value: item.id
          });
        });
        datas.exhibition_area.map(del => {
          this.ExhibitionData.push({
            label: del.area_name,
            value: del.id
          });
        });
        this.optionsExhibits = datas.exhibitor_cate;
      } else {
        // alert(res.message | '')
      }
    });
  },
  beforeRouteEnter (to, from, next) {
    if (from.name == 'BrandPresence') {
      sessionStorage.setItem('BrandPresence_back', true)
    }
    next()
  }
};
</script>
<style scoped  lang="scss">
.subGet {
  background-color: #0e9b32;
  border: 1px solid #0e9b32;
}
.dm-table.el-table th.is-leaf,
.dm-table.el-table td {
  border: none;
}
.dm-table-head {
  font-size: 16px;
  color: #333333;
}

.pageAction {
  margin-top: 40px;
  text-align: center;
  ::v-deep {
    .active {
      background-color: #0e9b32 !important;
    }
    li {
      &:hover {
        color: #27ea58 !important;
      }
    }
  }
}
.swiper-container {
  height: 500px;
  width: 100%;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      background-color: #42b983;
      text-align: center;
      line-height: 500px;
      height: 100%;
      width: 100%;
      background: url(../assets/images/banner4.jpg) no-repeat center top;
      background-size: 1920px 500px;
    }
  }
}
.banner {
  width: 100%;
  height: 500px;
  background: url("../assets/images/81.png") no-repeat;
  background-size: 100% 100%;

  .banner-btn {
    position: absolute;
    top: 315px;
    z-index: 100;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    background: rgba(51, 51, 51, 0);
    transition: all 0.1s;

    img {
      vertical-align: middle;
    }

    &:hover {
      background: rgba(51, 51, 51, 0.5);
    }

    &.banner-btn-left {
      left: 100px;
    }
    &.banner-btn-right {
      right: 100px;
    }
  }
}

.wrap {
  padding: 80px 0 0 0;
  .wrap-left {
    float: left;
    height: 600px;
    width: 285px;

    .wrap-left-top {
      width: 285px;
      height: 60px;
      background: #0e9b32;
      margin-bottom: 30px;

      p {
        font-weight: 400;
        color: #ffffff;
        line-height: 60px;
        font-size: 22px;
        padding-left: 20px;
      }
    }

    .wrap-left-bottom-wrap {
      padding-left: 15px;
      /deep/ .el-form-item__label {
        line-height: 1.2em;
        padding-top: 0.5em;
      }
    }
  }

  .wrap-right {
    float: right;
    width: 895px;
    padding-bottom: 80px;
  }
}

.btn-wrap {
  text-align: center;
}
.small-width {
  width: 86px;
}
.medium-width {
  width: 180px;
}
.ml8 {
  margin-left: 8px;
}
.form-submit {
  width: 120px;
}
</style>
